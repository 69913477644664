@font-face {
  font-family: 'AuthenticSans';
  src: url('./fonts/AUTHENTICSans-60.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AuthenticSans';
  src: url('./fonts/AUTHENTICSans-90.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AuthenticSans';
  src: url('./fonts/AUTHENTICSans-130.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AuthenticSans';
  src: url('./fonts/AUTHENTICSans-150.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DejaVuSansMono';
  src: url('./fonts/DejaVuSansMono.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'AuthenticSans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  color: white;
  background-color: gray;
}
svg {
  font-family: 'AuthenticSans', sans-serif !important;   
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-header {
  text-align: center;
}

h1 {
  position: absolute;
  top: 30px;
  left: 30px;
  margin: 10px;
}

#clock, #counter {
  font-size: 24px;
  margin-bottom: 20px;
}

a {
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  &.external {
      background: url('../public/SVG/Map/Btn_Link.svg') no-repeat left center;
      background-size: 15px;
      padding-left: 20px;
      white-space: nowrap;
  }
}