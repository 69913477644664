.map-detail {

    .brg {
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #FF4D25;
    }
    .frg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;

        .btn-back {
            position: absolute;
            top: 20px;
            left: 20px;
            width: 40px;
            // height: 100px;
            background: none;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
        }
        .window {
            position: absolute;
            top: 100px;
            left: 10px;
            width: 120px;
            height: auto;
            transform: skew(0turn, -30deg);
        }
        .mascot {
            position: absolute;
            width: 120px;
            bottom: 20px;
            left: 45px;
        }
        .content {
            position: absolute;
            left: 173px;
            top: 25px;
            right: 10px;
            $content-width: min(calc(100vw - 280px), 380px);
            width: $content-width;
            height: calc($content-width * 0.7);
            @media screen and (max-width: 585px) {
                $content-width: calc(100vw - 220px);
                width: $content-width;
                height: calc($content-width * 0.7);
            }
            padding: 20px;
            color: black;
            .content-inner {
                height: 100%;
                width: 97%;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    display: none;
                }

                h1 {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin: 0;
                    font-size: 17px;
                }
                p {
                    position: relative;
                    font-family: 'AuthenticSans';
                    font-size: 14px;
                    font-weight: 400;
                    padding-right: 5px;
                }
            }

            figure {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
            }

            .arrows {
                position: absolute;
                bottom: 20px;
                top: calc(50% - 12px);
                right: 5px;
                width: 20px;
                cursor: pointer;
            }
        }

        .link-items {
            display: flex;
            justify-content: space-around;
            align-items: end;
            gap: 10px;
            width: calc(100vw - 230px);
            position: absolute;
            bottom: 35px;
            right: 60px;
            .link-item-wrapper {
                position: relative;
                a {
                    cursor: pointer;
                    display: block;
                    transition: transform 0.1s ease-in-out;
                    &:hover {
                        transform: scale(1.1);
                    }
                    img {
                        position: relative;
                    }
                }
                .link-tooltip {
                    position: absolute;
                    bottom: -25px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 14px;
                    white-space: nowrap;
                    font-family: 'AuthenticSans';
                    color: white;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                    pointer-events: none;
                    visibility: hidden;
                }
                .link-tooltip.visible {
                    opacity: 1;
                    visibility: visible;
                }
            }
            @media screen and (max-width: 585px) {
                gap: 1px;
                img {
                    width: 15vw;
                }
            }
        }
    }
}