#map {
    .brg-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;

        .sky {
            background-color: #3ec0d7;
            width: 100%;
            flex: 1;
            .cloud {
                position: absolute;
                width: 70px;
            }
        }
        .ground {
            background-color: #e5e5e5;
            width: 100%;
            height: 100px;
        }
    }

    .foreground {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        .building-container {
            position: fixed;
            .building-sign {
                position: relative;
                display: flex;
                justify-content: center;
                width: 100px;
                left: 30px;
                overflow: hidden;
                bottom: -30px;
                height: 150px;
                
                &.md {
                    height: 110px;
                }
                &.sm {
                    height: 80px;
                }
                &.left {
                    transform: scaleX(-1);
                    left: -30px;
                    .text {
                        transform: scaleX(-1);
                    }
                }

                .svg,
                .text {
                    position: absolute;
                }
                .text {
                    height: 11px;
                    display: flex;
                    align-items: center;
                    font-family: "DejaVuSansMono";
                    font-size: 10px;
                    text-transform: uppercase;
                    top: 15px;
                    color: #000;
                    text-align: center;
                    font-weight: bold;
                    @supports (-webkit-hyphens:none) {
                        // font-synthesis: none !important;
                        letter-spacing: 0.5px;
                    }
                }
            }
            .building {
                position: relative;
            }
        }

        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          
        .building-container {
            opacity: 0;
            will-change: opacity;
        }

        .building-container {
            transition: transform 0.3s ease-in-out; // Ensures smooth transformation
        }
        .building-container:hover {
            // animation: pulse 0.6s ease-in-out;
            transform: scale(1.1);
            cursor: pointer;
        }

        .building-container-1 {
            left: 3%;
            bottom: 50px;
            z-index: 10;
            .building-sign {
                z-index: 11;
                .text {
                    z-index: 12;
                }
            }
            .building {
                width: 100px;
            }
        }
        .building-container-2 {
            left: 13%;
            bottom: -20px;
            z-index: 30;
            .building-sign {
                z-index: 31;
                .text {
                    z-index: 32;
                }
            }
            .building {
                width: 90px;
            }
        }
        .building-container-3 {
            left: 23%;
            bottom: 30px;
            z-index: 20;
            .building-sign {
                z-index: 21;
                .text {
                    z-index: 22;
                }
            }
            .building {
                width: 110px;
            }
        }
        .building-container-4 {
            left: 49%;
            bottom: 32px;
            z-index: 10;
            .building-sign {
                z-index: 11;
                .text {
                    z-index: 12;
                }
            }
            .building {
                width: 100px;
            }
        }
        .building-container-5 {
            left: 64%;
            bottom: 0px;
            z-index: 20;
            .building-sign {
                z-index: 21;
                .text {
                    z-index: 22;
                }
            }
            .building {
                width: 100px;
            }
        }
        .building-container-6 {
            left: 80%;
            bottom: 30px;
            z-index: 10;
            .building-sign {
                z-index: 11;
                .text {
                    z-index: 12;
                }
            }
            .building {
                width: 100px;
            }
        }

        .led-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .led {
                position: fixed;
            width: 12px;
            }
            .led-on {
                width: 50px;
            }
        }
        
    }
}
