.recipe-container {
    background-color: rgb(178, 178, 178);
    width: 100vw;
    height: 100vh;
    padding: 20px 45px;
    box-sizing: border-box;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .mascot {
        position: relative;
        left: -20px;
        width: 90px;
        height: 90px;
        // margin-bottom: 10px;
    }
    
    .step-navigation {
        display: flex;
        justify-content: end;
        align-items: center;
        color: white;
        font-family: 'AuthenticSans';
        font-size: 14px;
        font-weight: bold;
        img {
            width: 13px;
        }
        span {
            padding: 0 5px;
        }
        button {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
        }
        padding-bottom: 4px;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: calc(100vh - 170px);
        
        .text, .content {
            background-color: white;
            padding: 15px;
        }

        .text {
            color: black;
            font-family: 'AuthenticSans';
            .title {
                font-weight: bold;
                margin-top: 0;
                margin-bottom: 10px;
            }

            p {
                margin: 0;
            }
        }

        .content {
            color: black;
            position: relative;
            // overflow-y: auto;

            &.image {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
                .multiple-images {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    flex-direction: column;
                    gap: 10px;
                    img {
                        max-height: calc(50% - 5px); // Half the container height minus half the gap
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }

            &:nth-child(1):not(.code) {
                height: min-content;
                // overflow: clip;
            }

            pre {
                margin: 0;
                white-space: pre-wrap;
                word-wrap: break-word;
                font-size: 14px;
            }

            &.code {
                overflow: hidden;
                code {
                    font-family: 'DejaVuSansMono';
                    font-size: 14px;
                    max-height: 300px; // Set a max height to trigger vertical scrollbar
                    max-width: 100%; // Ensure code doesn't overflow horizontally
                    white-space: pre; // Preserve line breaks and spaces
                    scrollbar-width: thin; // For Firefox
                    scrollbar-color: #888 #f1f1f1; // For Firefox

                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px; // For horizontal scrollbar
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }

                    &::-webkit-scrollbar-corner {
                        background: #f1f1f1;
                    }
                }

                pre.code-container {
                    overflow: scroll !important;
                }
                .code-container {
                    padding: 15px !important;
                    overflow: auto;
                    margin: 0px;
                }

                .copy-button {
                    position: sticky;
                    bottom: 15px;
                    right: 15px;
                    float: right;
                    margin-top: -50px;
                    background-color: #0000FF;
                    color: white;
                    border: none;
                    cursor: pointer;
                    font-size: 21px;
                    font-weight: bold;
                    border: black 4px solid;
                    width: 100px;
                    transition: transform 0.1s ease;

                    &:hover {
                        transform: scale(1.05);
                    }

                    &:active {
                        transform: scale(0.95);
                    }
                }
            }
        }
    }

    .popover-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .popover-content {
            background-color: white;
            color: black;
            padding: 15px;
            margin: 25px;
        }

        .close-btn {
            float: right;
            background-color: #000;
            color: white;
            border: none;
            cursor: pointer;
            font-size: 21px;
            font-weight: bold;
            border: black 4px solid;
            width: 40px;
        }
    }

    .checklist-item {
        list-style-type: none;
        padding: 0;

        li {
            label {
                display: flex;
                align-items: center;

                input[type="checkbox"] {
                    margin-right: 10px;
                }

                &:has(input[type="checkbox"]:checked) {
                    // text-decoration: line-through;
                }
            }
            &:hover {
                background-color: #f1f1f1;
            }
        }
    }

    .checklist-images {
        position: relative;
        width: 100%;
        height: 300px; // Adjust this value as needed
        margin-top: 15px;
        margin-bottom: 25px;

        .image-container {
            position: absolute;
            width: 175px;
            height: 175px;
            &.many-images {
                // width: 130px;
                // height: 130px;
            }
            transition: all 0.3s ease;

            .image-wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                    transition: transform 0.3s ease;
                    // box-shadow: 0 2px 5px rgba(0,0,0,0.2);

                    &:hover {
                        transform: scale(1.2);
                        cursor: pointer;
                        z-index: 10;
                    }

                    &.zoomed {
                        transform: scale(1.1);
                        z-index: 5;
                    }

                    &.unchecked {
                        filter: grayscale(100%) opacity(50%);
                    }

                    &.checked {
                        filter: none;
                    }
                }
            }
        }
    }
}